import React, { useEffect } from 'react';
import { Box, List, Typography } from '@mui/material';
import { ChatCard } from './components/ChatCard';
import { FiCommand } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import LogoutButton from './components/Logout/LogoutButton';
import { useMediaQuery } from '@mui/material';

import GroupForm from '../groups/CreateGroupForm';
import { useGetOrSearchChatGroupsQuery } from '../../../store/feature/chat/chatApi';

const ChatSidebarGroups = ({
    searchText,
    hideForm,
    setShowGroupForm,
    showGroupForm,
    nextButton,
    clickNext,
    setClickNext,
    handleChange
}) => {
    const { data: groups, isLoading: isGroupsLoading } =
        useGetOrSearchChatGroupsQuery(searchText);

    const location = useLocation();
    const isSmallScreen = useMediaQuery('(max-width: 768px)');


    const [tab, setTab] = React.useState('');
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabFromUrl = urlParams.get('tab');
        setTab(tabFromUrl || 'all');
    }, [location.search]);

    useEffect(() => {
        if (tab) {
            setShowGroupForm(false);
        }
    }, [tab, setShowGroupForm]);

    return (
        <Box>
          {isGroupsLoading && (
                <Box className="flex items-center justify-center h-20 gap-4">
                    <Typography
                        sx={{
                            color: 'teal',
                            fontSize: isSmallScreen ? '1rem' : '1.25rem',
                            fontWeight: 'bold'
                        }}
                    >
                        Loading...
                    </Typography>
                    <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
                </Box>
            )}

            {!showGroupForm ? (
                <>
                    <List
                        sx={{
                            display: 'flex',
                            pt: '12px',
                            flexDirection: 'column',
                            overflowY: 'scroll',
                            maxHeight:
                                'calc(100dvh - (101px + 64px + 16px + 40px + 8px))',
                            zIndex: 1
                        }}
                    >
                        {groups?.map((group, index) => (
                            <ChatCard
                                imageURL={group?.image_url}
                                name={group?.name}
                                key={index}
                                userId={group?.uuid}
                                messageCount={group?.unread_messages}
                                message={group?.latest_message?.body}
                                time={group?.latest_message?.created_at}
                                isActive={group?.status}
                                isGroup={true}
                            />
                        ))}
                    </List>
                    <LogoutButton />
                </>
            ) : (
                <GroupForm
                    hideForm={hideForm}
                    searchText={searchText}
                    nextButton={nextButton}
                    clickNext={clickNext}
                    setClickNext={setClickNext}
                    handleChange={handleChange}
                />
            )}
        </Box>
    );
};

export default ChatSidebarGroups;
