import React, { useState } from 'react';
import { Button } from '@mui/material';
import LogoutModal from './LogoutModal';
import { useMediaQuery } from '@mui/material';

const LogoutButton = () => {
    const [open, setOpen] = useState(false);
    const isSmallerScreen = useMediaQuery('(max-width: 900px)');
    const isSmallScreen = useMediaQuery('(max-width: 1080px)');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        window.location.reload();
    };

    return (
        <>
            <Button
                sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 10,
                    backgroundColor: '#4056B5',
                    width: isSmallScreen ? '8%' : '80%',
                    maxWidth: '256px',
                    textAlign: 'center',
                    '&:hover': {
                        backgroundColor: '#4056F5'
                    }
                }}
                onClick={handleOpen}
            >
                <span
                    style={{
                        fontSize: isSmallScreen ? '12px' : 'inherit'
                    }}
                >
                    {isSmallScreen ? 'Logout' : 'Logout Chat'}
                </span>{' '}
            </Button>

            <LogoutModal
                open={open}
                handleClose={handleClose}
                handleLogout={handleLogout}
            />
        </>
    );
};

export default LogoutButton;
