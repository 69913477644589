import React, { useEffect } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdOutlineKeyboardBackspace } from "react-icons/md";

import {
    Box,
    TextField,
    InputAdornment,
    Typography,
    Button
} from '@mui/material';
import { useGetChatProfileQuery } from '@store/feature/chat/chatApi';
import UserAvatar from './components/UserAvatar';
import { Link, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

import { FiCommand } from 'react-icons/fi';
import { useMediaQuery } from '@mui/material';

import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from '@utils';

const ChatSidebarTop = ({ handleChange, hideForm, showGroupForm }) => {
    const location = useLocation();

    const [tab, setTab] = React.useState('');
    const isSmallScreen = useMediaQuery('(max-width: 1080px)');

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const tabFromUrl = urlParams.get('tab');

        if (tab !== tabFromUrl) {
            setTab(tabFromUrl || 'all');
        }
    }, [location.search, tab]);

    const { data: profile, isLoading: isGetProfileLoading } =
        useGetChatProfileQuery();

    const addQueryParamWithoutRepeating = (param, value) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(param, value);

        return `${
            location.pathname.includes('gas')
                ? `${GAS_PATH.CHAT}?tab=${value}`
                : location.pathname.includes('electricity')
                ? `${ELECTRICITY_PATH.CHAT}?tab=${value}`
                : `${COMMON_PATH.CHAT}?tab=${value}`
        }`;
    };

    return (
        <React.Fragment>
            {isGetProfileLoading ?  (
                <Box className="flex items-center justify-center h-20 gap-4">
                    <Typography
                        sx={{
                            color: 'teal',
                            fontSize: isSmallScreen ? '1rem' : '1.25rem',
                            fontWeight: 'bold'
                        }}
                    >
                        Loading...
                    </Typography>
                    <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            position: 'sticky',
                            top: '0',
                            zIndex: '100',
                            backgroundColor: 'white'
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#27485D',
                                height: '64px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                px: '16px',
                                mb: '16px'
                            }}
                        >
                            <UserAvatar
                                isActive={true}
                                fullName={profile?.fullname}
                                imgSource={profile?.image_url}
                            />
                            {!isSmallScreen && (
                                <Typography
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        ml: '12px',
                                        color: 'white'
                                    }}
                                    variant="body2"
                                >
                                    {profile?.fullname}
                                </Typography>
                            )}
                        </Box>
                        <Box>
                            {tab !== 'groups' && !isSmallScreen && (
                                <span
                                    style={{
                                        color: '#27485D',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        paddingLeft: '17px',
                                        paddingBottom: '5px',
                                        paddingRight: '10px'

                                        // border: '2px solid #000000'
                                    }}
                                >
                                    Messages
                                </span>
                            )}
                            {!showGroupForm && tab === 'groups' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '25px',
                                        paddingTop: '4px',
                                        gap: '20px'
                                        // border: '2px solid #000000',
                                    }}
                                >
                                    {!isSmallScreen && (
                                        <span
                                            style={{
                                                color: '#27485D',
                                                fontSize: '14px',
                                                fontWeight: '600',
                                                paddingRight: '10px',
                                                paddingBottom: '5px',
                                                paddingLeft: '17px'
                                            }}
                                        >
                                            Messages
                                        </span>
                                    )}
                                    <Button
                                        sx={{
                                            width: isSmallScreen
                                                ? '25%'
                                                : '100%',
                                            height: isSmallScreen
                                                ? '35px'
                                                : '30px',
                                            // textTransform: 'capitalize',
                                            fontSize: isSmallScreen
                                                ? '9px'
                                                : '12px',
                                            fontWeight: '500',
                                            color: '#2facfe',
                                            backgroundColor: '#f0f0f0',
                                            '&:hover': {
                                                backgroundColor: '#2facfe',
                                                color: '#ffffff'
                                            },
                                            borderRadius: '25px',
                                            marginBottom: '5px',
                                            position: 'sticky',
                                            top: '0',
                                            // marginLeft: isSmallScreen?'10px':'0px',
                                            marginRight: 'auto',
                                            marginLeft: 'auto'
                                        }}
                                        onClick={hideForm}
                                    >
                                        {/* {isSmallScreen ? (
                                            <FaPlus />
                                        ) : ( */}
                                        <>
                                            <FaPlus
                                                style={{ marginRight: '8px' }}
                                            />
                                            <span>NEW GROUP</span>
                                        </>
                                        {/* )} */}
                                    </Button>
                                </div>
                            )}

                            {showGroupForm && tab === 'groups' && (
                                <div className='flex flex-between'>
                                    <span
                                        className="ml-4 pt-[4px] font-[32px]"
                                        onClick={hideForm}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <MdOutlineKeyboardBackspace size={20} />
                                    </span>
                                    <span
                                        style={{
                                            color: '#27485D',
                                            fontSize: isSmallScreen
                                                ? '11px'
                                                : '14px',
                                            fontWeight: '600',
                                            paddingLeft: isSmallScreen
                                                ? '10px'
                                                : '17px',
                                            paddingTop: '4px'
                                        }}
                                    >
                                        Create New Group
                                    </span>
                                </div>
                            )}

                            {/* Add buttons for Single Chats, Group Chats, and All */}

                            <div>
                                <Box
                                    sx={{
                                        '& > button': { mx: 1 },

                                        my: 2,
                                        mx: 1,
                                        display: 'flex',
                                        flexDirection: isSmallScreen
                                            ? 'column'
                                            : 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        px: isSmallScreen ? '4px' : '8px',
                                        py: '4px',
                                        mt: isSmallScreen ? '10px' : '20px',
                                        position: 'sticky',
                                        top: '0',
                                        zIndex: '1000',
                                        borderRadius: '5px',
                                        gap: isSmallScreen ? '5px' : '0px',

                                        backgroundColor: '#eaeff2'
                                    }}
                                >
                                    <Button
                                        component={Link}
                                        to={addQueryParamWithoutRepeating(
                                            'tab',
                                            'all'
                                        )}
                                        sx={{
                                            backgroundColor:
                                                tab === 'all'
                                                    ? '#2facfe'
                                                    : 'initial',
                                            color:
                                                tab === 'all'
                                                    ? 'white'
                                                    : 'initial',
                                            fontSize: '11px',
                                            padding: '4px 16px', // Increase the length of the button
                                            '&:hover': {
                                                backgroundColor: '#2facfe',
                                                color: 'white'
                                            },
                                            marginRight: '5px',
                                            width: '50%'
                                        }}
                                    >
                                        All
                                    </Button>

                                    <Button
                                        component={Link}
                                        to={addQueryParamWithoutRepeating(
                                            'tab',
                                            'groups'
                                        )}
                                        sx={{
                                            backgroundColor:
                                                tab === 'groups'
                                                    ? '#2facfe'
                                                    : 'initial',
                                            color:
                                                tab === 'groups'
                                                    ? 'white'
                                                    : 'initial',
                                            fontSize: '11px',
                                            padding: '4px 16px', // Increase the length of the button

                                            '&:hover': {
                                                backgroundColor: '#2facfe',
                                                color: 'white'
                                            },
                                            // marginRight: '5px',
                                            width: '50%'
                                        }}
                                    >
                                        Groups
                                    </Button>
                                </Box>

                                {(!showGroupForm ||
                                    (showGroupForm && tab !== 'groups') ||
                                    !showGroupForm) && ( // (showGroupForm && clickNext)) && (
                                    <Box
                                        sx={{
                                            px: isSmallScreen ? '8px' : '16px',
                                            position: 'sticky',
                                            top: '0',
                                            pb: '8px',
                                            width: isSmallScreen
                                                ? '90%'
                                                : '100%'
                                        }}
                                    >
                                        {!isSmallScreen && (
                                            <TextField
                                                label="Search by name"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon fontSize="small" />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={handleChange}
                                            />
                                        )}
                                        {isSmallScreen && (
                                            <TextField
                                                label="Search"
                                                size="small"
                                                onChange={handleChange}
                                            />
                                        )}
                                    </Box>
                                )}
                            </div>
                        </Box>
                    </Box>
                </>
            )}
        </React.Fragment>
    );
};

export default ChatSidebarTop;
