import React, { useState } from 'react';
import { Box, Modal, Switch, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BlockIcon from '@mui/icons-material/Block';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import BlockModal from './BlockModal';
import DeleteGroupModal from './DeleteGroupModal';
import MuteModal from './MuteModal';
import UnmuteModal from './UnmuteModal';
import UnBlockModal from './UnBlockModal';
import AddMemberModal from './AddMemberModal';
import { IoMdRemove } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import {
    useGetChatGroupQuery,
    useRemoveGroupMemberMutation
} from '@store/feature/chat/chatApi';
import UserAvatar from './UserAvatar';
import {
    useGetChatProfileQuery,
    useGetGroupMembersQuery
} from '../../../../store/feature/chat/chatApi';
import { GAS_PATH, ELECTRICITY_PATH, COMMON_PATH } from '@utils';
import { Link, useLocation } from 'react-router-dom';
import './GroupChatDetails.css';
import { FiCommand } from 'react-icons/fi';
import { useMediaQuery } from '@mui/material';

const GroupChatDetails = ({ onClose, open }) => {
    const { id } = useParams();
    const location = useLocation();

    const isSmallScreen = useMediaQuery('(max-width: 1080px)');
    const isSmallerScreen = useMediaQuery('(max-width: 900px)');

    let { data: groupProfile, isLoading: isGroupLoading } =
        useGetChatGroupQuery(id);
    const { data: profile } = useGetChatProfileQuery();

    let [removeMember] = useRemoveGroupMemberMutation();
    let { data: groupMembers, isLoading: isGroupMemberLoading } =
        useGetGroupMembersQuery(id);

    // filtering groupMembers to remove the owner from the list
    const groupChatMembers = groupMembers?.filter(
        (member) => member.uuid !== groupProfile?.owner
    );

    // extracting group owner from groupMembers
    const groupOwner = groupMembers?.find(
        (member) => member.uuid === groupProfile?.owner
    );

    // const { data: sharedMedia } = useGetChatSharedMediaQuery(id);

    const handleRemoveMember = async (userId) => {
        try {
            await removeMember({ groupId: id, userId }).unwrap();
            // Handle success (e.g., show a success message or update the UI)
        } catch (error) {
            console.log('Cannot Delete ', error);
        }
    };
    // open mute modal when checked
    const handleChange = (event) => {
        if (event.target.checked) {
            handleOpenMuteModal();
        } else {
            handleOpenUnmuteModal();
        }
    };

    // open block modal
    const [openBlockModal, setOpenBlockModal] = useState(false);
    const handleOpenBlockModal = () => setOpenBlockModal(true);
    const handleCloseBlockModal = () => setOpenBlockModal(false);

    // unblock modal
    const [openUnblockModal, setOpenUnblockModal] = useState(false);
    const handleOpenUnblockModal = () => setOpenUnblockModal(true);
    const handleCloseUnblockModal = () => setOpenUnblockModal(false);

    // open delete modal
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);

    // mute modal
    const [openMuteModal, setOpenMuteModal] = useState(false);
    const handleOpenMuteModal = () => setOpenMuteModal(true);
    const handleCloseMuteModal = () => setOpenMuteModal(false);

    // unmute modal
    const [openUnmuteModal, setOpenUnmuteModal] = useState(false);
    const handleOpenUnmuteModal = () => setOpenUnmuteModal(true);
    const handleCloseUnmuteModal = () => setOpenUnmuteModal(false);

    // add member modal
    const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
    const handleOpenAddMemberModal = () => setOpenAddMemberModal(true);
    const handleCloseAddMemberModal = () => setOpenAddMemberModal(false);

    return (
        <>
            {/* block modal */}
            <Modal open={openBlockModal} onClose={handleCloseBlockModal}>
                <BlockModal handleClose={handleCloseBlockModal} />
            </Modal>
            {/* Unblock Modal */}
            <Modal open={openUnblockModal} onClose={handleCloseUnblockModal}>
                <UnBlockModal handleClose={handleCloseUnblockModal} />
            </Modal>
            {/* Delete Modal */}
            <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
                <DeleteGroupModal handleClose={handleCloseDeleteModal} />
            </Modal>
            {/* Mute Modal */}
            <Modal open={openMuteModal} onClose={handleCloseMuteModal}>
                <MuteModal handleClose={handleCloseMuteModal} />
            </Modal>
            {/* UnMute Modal */}
            <Modal open={openUnmuteModal} onClose={handleCloseUnmuteModal}>
                <UnmuteModal handleClose={handleCloseUnmuteModal} />
            </Modal>
            {/* Add Member Modal */}
            <Modal
                open={openAddMemberModal}
                onClose={handleCloseAddMemberModal}
            >
                <AddMemberModal
                    handleClose={handleCloseAddMemberModal}
                    groupMembers={groupMembers}
                    isGroupMemberLoading={isGroupMemberLoading}
                />
            </Modal>
            <Box
                sx={{
                    backgroundColor: '#27485D',
                    height: '64px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: '16px',
                    position: 'sticky',
                    flex: '0 0 auto',
                    top: 0,
                    zIndex: 990
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '12px',
                        py: '16px'
                    }}
                >
                    <CloseIcon
                        sx={{
                            color: 'white',
                            cursor: 'pointer'
                        }}
                        onClick={onClose}
                    />
                    <Typography
                        sx={{
                            color: 'white'
                        }}
                    >
                        Contact Info
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    mt: '16px',
                    px: '18px'
                }}
            >
                <Box>
                    {isGroupLoading ? (
                        <Box className="flex items-center justify-center h-20 gap-4">
                            <Typography
                                sx={{
                                    color: 'teal',
                                    fontSize: isSmallScreen
                                        ? '1rem'
                                        : '1.25rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                Loading...
                            </Typography>
                            <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: '16px',
                                gap: '8px'
                            }}
                        >
                            <UserAvatar
                                fullName={groupProfile?.name}
                                imgSource={groupProfile?.user?.image_url}
                            />
                            <Typography
                                sx={{
                                    p: 0,
                                    m: 0,
                                    fontWeight: 'bold',
                                    fontSize: '20px'
                                }}
                            >
                                {groupProfile?.name}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                ></Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Name
                        </Typography>
                        <Typography
                            sx={{
                                color: 'black',
                                fontWeight: 'normal'
                            }}
                        >
                            {groupProfile?.name}
                        </Typography>
                    </Box>
                </Box>

                <Divider />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '2px',
                        marginTop: '16px'
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: isSmallerScreen ? '13px' : '15px',
                            color: 'gray',
                            marginLeft: '16px',
                            marginTop: '8px'
                        }}
                    >
                        {groupMembers?.length} Members
                    </Typography>
                    {profile?.uuid === groupProfile?.owner && (
                        <Typography
                            sx={{
                                fontWeight: 'bold',
                                '&:hover': {
                                    cursor: 'pointer'
                                },
                                borderRadius: '4px',
                                padding: '4px 6px',
                                background: '#2FACFE',
                                color: 'white',
                                fontSize: isSmallerScreen ? '12px' : '14px',
                                marginRight: '16px'
                            }}
                            onClick={handleOpenAddMemberModal}
                        >
                            Add Members
                        </Typography>
                    )}
                </Box>

                <Box
                    sx={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                        padding: '8px',
                        borderRadius: '8px',
                        scrollbarWidth: 'thin',
                        msOverflowStyle: 'none',
                        '&::-webkit-scrollbar': {
                            width: '6px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: 'black',
                            borderRadius: '4px'
                        },
                        '&': {
                            scrollbarWidth: 'thick',
                            scrollbarColor: 'gray transparent'
                        }
                    }}
                >
                    {(groupChatMembers || groupMembers) &&
                    (groupChatMembers?.length > 0 ||
                        groupMembers?.length > 0) ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                                mt: '2px'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flexColumn',
                                    gap: '8px',
                                    flexWrap: 'wrap'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '8px',
                                        background: '#F6F6F6',
                                        borderRadius: '6px',
                                        marginTop: '4px',
                                        gap: '8px'
                                    }}
                                >
                                    <Box
                                        sx={{ minWidth: '40px' }}
                                        component={Link}
                                        to={`${
                                            location.pathname.includes('gas')
                                                ? `${GAS_PATH.CHAT}/${groupOwner.uuid}?tab=all`
                                                : location.pathname.includes(
                                                      'electricity'
                                                  )
                                                ? `${ELECTRICITY_PATH.CHAT}/${groupOwner.uuid}?tab=all`
                                                : `${COMMON_PATH.CHAT}/${groupOwner.uuid}?tab=all`
                                        }`}
                                    >
                                        <UserAvatar
                                            fullName={groupOwner?.fullname}
                                            imgSource={groupOwner?.image_url}
                                            isActive={
                                                groupOwner?.uuid ===
                                                    profile?.uuid ||
                                                groupOwner?.status === 'active'
                                            }
                                        />
                                    </Box>
                                    {!isSmallerScreen && (
                                        <Typography
                                            component={Link}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: isSmallerScreen
                                                    ? '12px'
                                                    : '14px',
                                                marginRight: 'auto'
                                            }}
                                            to={`${
                                                location.pathname.includes(
                                                    'gas'
                                                )
                                                    ? `${GAS_PATH.CHAT}/${groupOwner.uuid}?tab=all`
                                                    : location.pathname.includes(
                                                          'electricity'
                                                      )
                                                    ? `${ELECTRICITY_PATH.CHAT}/${groupOwner.uuid}?tab=all`
                                                    : `${COMMON_PATH.CHAT}/${groupOwner.uuid}?tab=all`
                                            }`}
                                        >
                                            {groupOwner?.fullname}
                                        </Typography>
                                    )}

                                    <Box
                                        sx={{
                                            color: 'teal',
                                            fontWeight: 'normal',
                                            fontSize: '12px',
                                            justifySelf: 'flex-end',
                                            padding: '2px 10px',
                                            borderRadius: '24px',
                                            background: '#90EE90'
                                        }}
                                    >
                                        Admin
                                    </Box>
                                </Box>

                                {groupChatMembers.map((member, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '8px',
                                            background: '#F6F6F6',
                                            borderRadius: '6px',
                                            marginTop: '4px',
                                            gap: '8px'
                                        }}
                                    >
                                        <Box
                                            sx={{ minWidth: '40px' }}
                                            component={Link}
                                            to={`${
                                                location.pathname.includes(
                                                    'gas'
                                                )
                                                    ? `${GAS_PATH.CHAT}/${member.uuid}?tab=all`
                                                    : location.pathname.includes(
                                                          'electricity'
                                                      )
                                                    ? `${ELECTRICITY_PATH.CHAT}/${member.uuid}?tab=all`
                                                    : `${COMMON_PATH.CHAT}/${member.uuid}?tab=all`
                                            }`}
                                        >
                                            <UserAvatar
                                                fullName={member?.fullname}
                                                imgSource={member?.image_url}
                                            />
                                        </Box>
                                        {!isSmallerScreen && (
                                            <Box
                                                sx={{
                                                    fontSize: isSmallerScreen
                                                        ? '12px'
                                                        : '14px',
                                                    marginRight: 'auto',
                                                    width: '150px'
                                                }}
                                                component={Link}
                                                to={`${
                                                    location.pathname.includes(
                                                        'gas'
                                                    )
                                                        ? `${GAS_PATH.CHAT}/${member.uuid}?tab=all`
                                                        : location.pathname.includes(
                                                              'electricity'
                                                          )
                                                        ? `${ELECTRICITY_PATH.CHAT}/${member.uuid}?tab=all`
                                                        : `${COMMON_PATH.CHAT}/${member.uuid}?tab=all`
                                                }`}
                                            >
                                                {member?.fullname}
                                            </Box>
                                        )}
                                        {profile?.uuid ===
                                            groupProfile?.owner && (
                                            <Tooltip title="Remove">
                                                <button
                                                    onClick={() =>
                                                        handleRemoveMember(
                                                            member?.uuid
                                                        )
                                                    }
                                                    className="
        bg-[#A6382A] text-white border-none rounded-lg p-2 w-16
        flex items-center justify-center cursor-pointer
        sm:rounded-md sm:p-1 sm:w-12
    "
                                                >
                                                    <IoMdRemove
                                                        size={
                                                            isSmallerScreen
                                                                ? 14
                                                                : 20
                                                        }
                                                    />
                                                </button>
                                            </Tooltip>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ) : null}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                >
                    <Box
                        sx={{
                            pb: '8px',
                            pt: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '12px'
                            }}
                        >
                            <NotificationsIcon />
                            <Typography>Mute</Typography>
                        </Box>
                        <Switch
                            checked={groupProfile?.muted}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            size="small"
                        />
                    </Box>
                    {groupProfile?.blocked ? (
                        <Box
                            sx={{
                                // px: '16px',
                                pb: '8px',
                                display: 'flex',
                                gap: '12px',
                                color: '#F15C6D',
                                cursor: 'pointer'
                            }}
                            onClick={handleOpenUnblockModal}
                        >
                            <BlockIcon />
                            <Typography>
                                Unblock {groupProfile?.name}
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                // px: '16px',
                                pb: '8px',
                                display: 'flex',
                                gap: '12px',
                                color: '#F15C6D',
                                cursor: 'pointer'
                            }}
                            onClick={handleOpenBlockModal}
                        >
                            <BlockIcon />
                            <Typography>
                                Block {groupProfile?.user?.fullname}
                            </Typography>
                        </Box>
                    )}

                    {/* Showing the Delete box only to the group owner */}
                    {profile?.uuid === groupProfile?.owner && (
                        <Box
                            sx={{
                                // px: '16px',
                                pb: '8px',
                                display: 'flex',
                                gap: '12px',
                                color: '#F15C6D',
                                cursor: 'pointer'
                            }}
                            onClick={handleOpenDeleteModal}
                        >
                            <DeleteOutlineOutlinedIcon />
                            <Typography>Delete Group</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default GroupChatDetails;
