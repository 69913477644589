import { useCallback, useEffect, useRef } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Layout from './layout/Layout';
import { COMMON_PATH, ELECTRICITY_PATH, GAS_PATH } from './utils';
import { NoAuth, RequireAuth } from './auth';
import { useAuth } from './hooks';
import {
    // gas pages
    TheEur,
    TheChf,
    TheProfile,
    TheClosingPrice,
    TtfEur,
    PegEur,
    Marketplace,
    Intraday,
    Watchlist,
    Overview,
    // Market,
    Chat,
    PriceProfiling,
    Settings,
    Notification,

    // electricity pages
    Eur,
    Chf,

    // common pages
    Home,
    Calendar,
    Orders,
    Disclaimer,
    Help,
    NewsFeed,
    LandingPage,
    Profile,
    ChangePassword,
    UpdatePassword,
    HedgingMargin
} from './pages';
import axios from 'axios';
import {
    apiGetChf,
    apiGetTheEur,
    apiGetMarket,
    apiGetNews,
    apiGetPriceprofile,
    apiGetProfilingRates,
    apiGetProfile,
    apiGetSetPriceProfile,
    apiGetTtfEur,
    apiGetMarketplace,
    apiGetBiogas,
    apiGetIntraday,
    apiGetPegnordEur,
    apiGetOrder,
    apiGetWatchlist,
    apiGetAccount,
    apiGetEndpoint,
    apiGetLimitOrder,
    apiGetTrailingStop,
    apiGetMinMaxRaw,
    apiGetCheEur,
    apiGetCheChf,
    apiGetAnnouncement,
    apiGetMinMaxDisplay,
    apiGetTrailingStopForDisplay,
    apiGetMinMaxOrders,
    apiGetMonthHistoricalPrices,
    apiGetQuarterHistoricalPrices,
    apiGetSeasonHistoricalPrices,
    apiGetDisableTrading,
    apiGetHedgeMargin,
    apiGetAnalysisSignal
} from '@store';
import { useDispatch } from 'react-redux';
import { SinglePriceProfile, UserManagement } from './pages/gas';
import { apiGetOrdersInfo } from './store/feature/order/orderSlice';
import { MainChatContent } from './pages/common/chat/[id]/MainChatContent';
import { LoginTemp } from './pages/common/chat/temp-login/Login';
import ChatAuthProvider from './pages/common/chat/ChatAuthProvider';
import {
    invalidateChatGroup,
    invalidateChatMessages,
    invalidateUnreadMessageCount,
    useDeliveredChatMessageMutation,
    useGetChatProfileQuery
} from './store/feature/chat/chatApi';
import notificationAudio from '@assets/notification_audio.mp3';
import LayoutWithoutSidebar from './layout/LayoutWithoutSidebar';
import ChatMessageContent from './pages/common/chat/[id]/ChatMessageContent';
import GroupChatMessageContent from './pages/common/chat/[id]/GroupChatMessageContent';
import { ContractDetails } from './components';
import The from './pages/gas/the/The';
import TTF from './pages/gas/ttf/TTF';
import PEG from './pages/gas/pegnord/Pegnord';
import useWS from './utils/socket';
import { setupMessageListener } from './utils/socket';

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const dispatch = useDispatch();
    const { data: chatUser } = useGetChatProfileQuery();
    const [deliveredMessage] = useDeliveredChatMessageMutation();

    const implementInterceptors = useCallback(() => {
        // Add a request interceptor
        axios.interceptors.request.use(
            function (config) {
                // Do something before request is sent
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error

                if (error.response.status === 401) {
                    setAuth({ session: false });
                    localStorage.removeItem('loginState');
                }
                return Promise.reject(error);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        implementInterceptors();
    }, [implementInterceptors]);

    useEffect(() => {
        if (auth?.session) {
            dispatch(apiGetProfile());
            dispatch(apiGetTheEur());
            dispatch(apiGetChf());
            dispatch(apiGetSetPriceProfile());
            dispatch(apiGetNews());
            dispatch(apiGetPriceprofile());
            dispatch(apiGetMarket());
            dispatch(apiGetTtfEur());
            dispatch(apiGetMarketplace());
            dispatch(apiGetBiogas());
            dispatch(apiGetIntraday());
            dispatch(apiGetPegnordEur());
            dispatch(apiGetOrder());
            dispatch(apiGetOrdersInfo());
            dispatch(apiGetWatchlist());
            dispatch(apiGetAccount());
            dispatch(apiGetEndpoint());
            dispatch(apiGetLimitOrder());
            dispatch(apiGetTrailingStop());
            dispatch(apiGetMinMaxRaw());
            dispatch(apiGetCheEur());
            dispatch(apiGetCheChf());
            dispatch(apiGetAnnouncement());
            dispatch(apiGetMinMaxDisplay());
            dispatch(apiGetTrailingStopForDisplay());
            dispatch(apiGetMinMaxOrders());
            dispatch(apiGetMonthHistoricalPrices());
            dispatch(apiGetQuarterHistoricalPrices());
            dispatch(apiGetSeasonHistoricalPrices());
            dispatch(apiGetProfilingRates());
            dispatch(apiGetDisableTrading());
            dispatch(apiGetHedgeMargin());
            dispatch(apiGetAnalysisSignal());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    // call apiGetTheEur in every 20 seconds
    const interval = setInterval(() => {
        if (auth?.session) {
            // gas
            dispatch(apiGetTheEur());
            dispatch(apiGetChf());
            dispatch(apiGetTtfEur());
            dispatch(apiGetPegnordEur());
            dispatch(apiGetMarket());
            dispatch(apiGetMarketplace());
            dispatch(apiGetBiogas());
            dispatch(apiGetIntraday());
            // electricity
            dispatch(apiGetCheEur());
            dispatch(apiGetCheChf());
        }
    }, 20000);

    useEffect(() => {
        return () => clearInterval(interval);
    }, [interval]);

    useEffect(() => {
        // Available routes for non-authenticated users
        const noAuthOnlyRoutes = ['/login', '/update_password'];
        if (!noAuthOnlyRoutes.includes(location.pathname)) {
            if (!auth?.session) {
                navigate('/login');
                localStorage.setItem('loginState', false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const { webSocket } = useWS(
        window.location.hostname === 'localhost'
            ? 'ws://localhost:8080/api/ws'
            : `wss://${window.location.hostname}/api/ws`
    );

    const audioRef = useRef(null);

    const playNewMessageSound = () => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0; // Reset audio to start
            audioRef.current.play().catch((error) => {
                console.error('Failed to play audio:', error);
            });
        } else {
            console.error(
                'Audio element is not initialized. Ensure the user interacts with the document first.'
            );
        }
    };

    const receiveMessage = useCallback(
        async (data) => {
            if (
                data?.type === 'user_online_status' ||
                data?.type === 'delete_message' ||
                data?.type === 'read_message' ||
                data?.type === 'delivered_message'
            ) {
                invalidateChatMessages(dispatch);
                return;
            }

            if (data?.type === 'group_message') {
                invalidateChatGroup(dispatch);
                invalidateUnreadMessageCount(dispatch);
                return;
            }

            if (data?.type === 'message') {
                // Set document title as New Message for 2 seconds
                document.title = 'New Message';
                setTimeout(() => {
                    document.title = 'PriceBlick';
                }, 2000);

                if (data?.muted !== true) {
                    playNewMessageSound();
                }
                invalidateChatMessages(dispatch);

                if (
                    data?.data?.from !== chatUser?.uuid &&
                    data?.data?.to === chatUser?.uuid
                ) {
                    try {
                        deliveredMessage(data?.data?.uuid);
                    } catch (error) {
                        console.log(error, 'Error in receiveMessage');
                    }
                }
            }
        },
        [chatUser, dispatch, deliveredMessage]
    );

    useEffect(() => {
        setupMessageListener(webSocket, receiveMessage);
    }, [receiveMessage, webSocket]);

    return (
        <>
            <audio ref={audioRef} src={notificationAudio} />

            <Routes>
                <Route element={<NoAuth />}>
                    <Route
                        path={COMMON_PATH.LANDINGPAGE}
                        element={<LandingPage />}
                    />
                    <Route
                        path={COMMON_PATH.UPDATE_PASSWORD}
                        element={<UpdatePassword />}
                    />
                </Route>
                <Route element={<RequireAuth />}>
                    {/* with sidebar layouts */}
                    <Route element={<LayoutWithoutSidebar />}>
                        <Route path={COMMON_PATH.HOME} element={<Home />} />
                        <Route
                            path={COMMON_PATH.PROFILE}
                            element={<Profile />}
                        />
                        <Route
                            path={COMMON_PATH.SETTINGS}
                            element={<Settings />}
                        />
                        <Route
                            path={`${COMMON_PATH.USER_MANAGEMENT}/:account_uuid`}
                            element={<UserManagement />}
                        />
                        <Route
                            path={COMMON_PATH.CHAT_LOGIN}
                            element={<LoginTemp />}
                        />
                        <Route path={COMMON_PATH.CHAT} element={<Chat />} />
                        <Route
                            path={COMMON_PATH.CHANGE_PASSWORD}
                            element={<ChangePassword />}
                        />
                        <Route
                            path={COMMON_PATH.CHAT_LOGIN}
                            element={<LoginTemp />}
                        />
                        <Route
                            path={COMMON_PATH.HEDGING_MARGIN}
                            element={<HedgingMargin />}
                        />
                        <Route element={<ChatAuthProvider />}>
                            <Route path={COMMON_PATH.CHAT} element={<Chat />}>
                                <Route
                                    path={`${COMMON_PATH.CHAT}/:id`}
                                    element={<MainChatContent />}
                                />
                            </Route>
                        </Route>
                    </Route>
                    {/* with sidebar layouts */}
                    <Route element={<Layout />}>
                        {/* gas path */}
                        <Route element={<The />}>
                            <Route
                                path={GAS_PATH.THE_EUR}
                                element={<TheEur />}
                            />
                            <Route
                                path={GAS_PATH.THE_CHF}
                                element={<TheChf />}
                            />
                            <Route
                                path={GAS_PATH.THE_PROFILE}
                                element={<TheProfile />}
                            />
                            <Route
                                path={GAS_PATH.THE_CLOSING_PRICE}
                                element={<TheClosingPrice />}
                            />
                            <Route
                                path={GAS_PATH.THE_CONTRACT_DETAILS}
                                element={<ContractDetails />}
                            />
                        </Route>
                        <Route element={<TTF />}>
                            <Route
                                path={GAS_PATH.TTF_EUR}
                                element={<TtfEur />}
                            />
                            <Route
                                path={GAS_PATH.TTF_CONTRACT_DETAILS}
                                element={<ContractDetails />}
                            />
                        </Route>
                        <Route element={<PEG />}>
                            <Route
                                path={GAS_PATH.PEG_EUR}
                                element={<PegEur />}
                            />
                            <Route
                                path={GAS_PATH.PEG_CONTRACT_DETAILS}
                                element={<ContractDetails />}
                            />
                        </Route>
                        <Route
                            path={GAS_PATH.MARKET_PLACE}
                            element={<Marketplace />}
                        />
                        <Route
                            path={GAS_PATH.INTRADAY}
                            element={<Intraday />}
                        />
                        <Route
                            path={GAS_PATH.CALENDAR}
                            element={<Calendar />}
                        />
                        <Route path={GAS_PATH.ORDERS} element={<Orders />} />
                        <Route
                            path={GAS_PATH.WATCHLIST}
                            element={<Watchlist />}
                        />
                        <Route
                            path={GAS_PATH.OVERVIEW}
                            element={<Overview />}
                        />
                        <Route
                            path={GAS_PATH.NEWS_FEED}
                            element={<NewsFeed />}
                        />
                        <Route
                            path={GAS_PATH.HEDGING_MARGIN}
                            element={<HedgingMargin />}
                        />
                        <Route
                            path={GAS_PATH.CHAT_LOGIN}
                            element={<LoginTemp />}
                        />
                        <Route element={<ChatAuthProvider />}>
                            <Route path={GAS_PATH.CHAT} element={<Chat />}>
                                {/* <Route
                                    path={`${GAS_PATH.CHAT}/:id`}
                                    element={<MainChatContent />}
                                /> */}
                                <Route
                                    path={`${GAS_PATH.CHAT}/:id`}
                                    element={<ChatMessageContent />}
                                />
                                <Route
                                    path={`${GAS_PATH.CHAT}/group/:id`}
                                    element={<GroupChatMessageContent />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path={GAS_PATH.PRICE_PROFILING}
                            element={<PriceProfiling />}
                        />
                        <Route
                            path={`${GAS_PATH.SINGLE_PRICE_PROFILE}/:uuid`}
                            element={<SinglePriceProfile />}
                        />
                        <Route path={GAS_PATH.HELP} element={<Help />} />
                        <Route
                            path={GAS_PATH.SETTINGS}
                            element={<Settings />}
                        />
                        <Route
                            path={`${GAS_PATH.USER_MANAGEMENT}/:account_uuid`}
                            element={<UserManagement />}
                        />
                        <Route
                            path={GAS_PATH.NOTIFICATIONS}
                            element={<Notification />}
                        />
                        <Route path={GAS_PATH.PROFILE} element={<Profile />} />
                        <Route
                            path={GAS_PATH.CHANGE_PASSWORD}
                            element={<ChangePassword />}
                        />

                        {/* electricity path */}
                        <Route
                            path={ELECTRICITY_PATH.NEWS_FEED}
                            element={<NewsFeed />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.CALENDAR}
                            element={<Calendar />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.CHE_EUR}
                            element={<Eur />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.CH_CHF}
                            element={<Chf />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.ORDERS}
                            element={<Orders />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.DISCLAIMER}
                            element={<Disclaimer />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.HELP}
                            element={<Help />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.PROFILE}
                            element={<Profile />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.CHANGE_PASSWORD}
                            element={<ChangePassword />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.SETTINGS}
                            element={<Settings />}
                        />
                        <Route
                            path={`${ELECTRICITY_PATH.USER_MANAGEMENT}/:account_uuid`}
                            element={<UserManagement />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.HEDGING_MARGIN}
                            element={<HedgingMargin />}
                        />
                        <Route
                            path={ELECTRICITY_PATH.CHAT_LOGIN}
                            element={<LoginTemp />}
                        />
                        <Route element={<ChatAuthProvider />}>
                            <Route
                                path={ELECTRICITY_PATH.CHAT}
                                element={<Chat />}
                            >
                                {/* <Route
                                    path={`${ELECTRICITY_PATH.CHAT}/:id`}
                                    element={<MainChatContent />}
                                /> */}
                                <Route
                                    path={`${ELECTRICITY_PATH.CHAT}/:id`}
                                    element={<ChatMessageContent />}
                                />
                                <Route
                                    path={`${ELECTRICITY_PATH.CHAT}/group/:id`}
                                    element={<GroupChatMessageContent />}
                                />
                            </Route>
                        </Route>
                        {/* common path */}
                    </Route>
                </Route>
            </Routes>
        </>
    );
}

export default App;
