import React from 'react';

const useChatAuth = () => {
    const token = localStorage.getItem('token');
    const auth = localStorage.getItem('auth');
    const refresh_token = localStorage.getItem('refresh_token');

    return { auth: token && auth === 'true' && refresh_token };
};

export default useChatAuth;
