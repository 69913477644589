import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { GAS_PATH, ELECTRICITY_PATH } from '@utils';

export const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token');

        if (!refreshToken) return { error: 'No refresh token found' };

        const response = await fetch('/chat/refresh', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ refresh_token: refreshToken })
        });

        if (!response.ok && response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('auth');
            window.location.href = window.location.pathname.includes('gas')
                ? `${GAS_PATH.CHAT}/login`
                : `${ELECTRICITY_PATH.CHAT}/login`;
            return { error: 'Invalid refresh token' };
        }
        if (!response.ok) return { error: 'Failed to refresh token' };

        const data = await response.json();
        localStorage.setItem('token', data.access_token);

        return {
            data: data.access_token
        };
    } catch (error) {
        return { error: 'Failed to refresh token' };
    }
};

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: '/chat',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.originalStatus === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const newToken = await refreshAccessToken();
                if (!newToken.error && newToken.data) {
                    // Retry the original request with the new token
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    console.error('Failed to refresh token', newToken.error);
                }
            } catch (error) {
                console.error('Failed to refresh token', error);
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            // Retry the original request after the token has been refreshed
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

export const chatApi = createApi({
    reducerPath: 'chatApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Chat'],
    endpoints: (builder) => ({
        getChatProfile: builder.query({
            query: () => '/profile',
            providesTags: ['Chat-Profile']
        }),
        getChatUsers: builder.query({
            query: () => '/users',
            providesTags: ['Chat']
        }),
        getChatUser: builder.query({
            query: (userId) => `/users/${userId}`,
            providesTags: ['Chat']
        }),
        getChatGroup: builder.query({
            query: (groupId) => `/groups/${groupId}`,
            providesTags: ['Chat']
        }),
        getChatMessages: builder.query({
            query: (userId) => `/messages?user=${userId}`,
            providesTags: ['Chat', 'Chat-Messages']
        }),
        getGroupMessages: builder.query({
            query: (groupId) => `/groups/${groupId}/messages`,
            providesTags: ['group']
        }),

        getGroupMembers: builder.query({
            query: (groupId) => `/groups/${groupId}/members`,
            providesTags: ['group']
        }),
        getChatSharedMedia: builder.query({
            query: (userId) => `/files?user=${userId}`,
            providesTags: ['Chat']
        }),
        getChatUnreadMessageCount: builder.query({
            query: () => `/unread_message_count`,
            providesTags: ['Chat', 'Unread-Message-Count']
        }),
        getOrSearchChatUsers: builder.query({
            query: (searchValue) => {
                if (searchValue) {
                    return `/users?q=${searchValue}`;
                }
                return '/messages';
            },
            providesTags: ['Chat']
        }),
        getOrSearchAllUsers: builder.query({
            query: (searchValue) => {
                if (searchValue) {
                    return `/users?q=${searchValue}`;
                }
                return '/users';
            },
            providesTags: ['Chat']
        }),
        getOrSearchChatGroups: builder.query({
            query: (searchValue) => {
                if (searchValue) {
                    return `/groups?q=${searchValue}`;
                }
                return '/groups';
            },
            providesTags: ['group']
        }),
        getOrSearchGroupMembers: builder.query({
            query: (searchValue, groupId) => {
                if (searchValue) {
                    return `/groups?q=${searchValue}`;
                }
                return `/groups/${groupId}/members`;
            },
            providesTags: ['group']
        }),
        createBotMessage: builder.mutation({
            query: (message) => ({
                url: '/chatbot',
                method: 'POST',
                body: message
            }),
            invalidatesTags: ['Chat']
        }),
        createChatMessage: builder.mutation({
            query: (message) => ({
                url: '/messages',
                method: 'POST',
                body: message,
                headers: {}
            }),
            invalidatesTags: ['Chat']
        }),

        createGroupMessage: builder.mutation({
            query: ({ groupId, body }) => ({
                url: `/groups/${groupId}/messages`,
                method: 'POST',
                body: { body },
                headers: {}
            }),
            invalidatesTags: ['group']
        }),

        createGroup: builder.mutation({
            query: (group) => ({
                url: '/groups',
                method: 'POST',
                body: group
            }),
            invalidatesTags: ['group']
        }),
        addGroupMembers: builder.mutation({
            query: ({ groupId, members }) => {
                return {
                    url: `/groups/${groupId}/members`,
                    method: 'POST',
                    body: { members }
                };
            },
            invalidatesTags: ['group']
        }),

        removeGroupMember: builder.mutation({
            query: ({ groupId, userId }) => {
                return {
                    url: `/groups/${groupId}/members/${userId}`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: ['group']
        }),
        readGroupMessage: builder.mutation({
            query: ({ groupId, messageId }) => {
                return {
                    url: `/groups/${groupId}/messages/${messageId}/read`,
                    method: 'POST'
                };
            },
            invalidatesTags: ['group', 'Unread-Message-Count']
        }),

        readChatMessage: builder.mutation({
            query: (messageId) => ({
                url: `/messages/${messageId}`,
                body: JSON.stringify({ status: 'read' }),
                method: 'PATCH'
            }),
            invalidatesTags: ['Chat']
        }),
        deliveredChatMessage: builder.mutation({
            query: (messageId) => ({
                url: `/messages/${messageId}`,
                body: JSON.stringify({ status: 'delivered' }),
                method: 'PATCH'
            }),
            invalidatesTags: ['Chat']
        }),
        deleteChatMessages: builder.mutation({
            query: (userId) => ({
                url: `/messages?user=${userId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Chat']
        }),
        deleteGroupMessages: builder.mutation({
            query: ({ groupId }) => ({
                url: `/groups/${groupId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['group']
        }),
        deleteChatMessageForMe: builder.mutation({
            query: (messageId) => ({
                url: `/messages/${messageId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Chat']
        }),
        deleteChatMessageForEveryone: builder.mutation({
            query: (messageId) => ({
                url: `/messages/${messageId}?delete_for=both`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Chat']
        }),
        blockChatUser: builder.mutation({
            query: (userId) => ({
                url: `/blocklists`,
                method: 'POST',
                body: JSON.stringify({ status: 'block', user_id: userId })
            }),
            invalidatesTags: ['Chat']
        }),
        unblockChatUser: builder.mutation({
            query: (userId) => ({
                url: `/blocklists`,
                method: 'POST',
                body: JSON.stringify({ status: 'unblock', user_id: userId })
            }),
            invalidatesTags: ['Chat']
        }),
        muteChatUser: builder.mutation({
            query: (userId) => ({
                url: `/mutes`,
                method: 'POST',
                body: JSON.stringify({ status: 'mute', user_id: userId })
            }),
            invalidatesTags: ['Chat']
        }),
        unmuteChatUser: builder.mutation({
            query: (userId) => ({
                url: `/mutes`,
                method: 'POST',
                body: JSON.stringify({ status: 'unmute', user_id: userId })
            }),
            invalidatesTags: ['Chat']
        })
    })
});

export const invalidateChatMessages = (dispatch) => {
    dispatch(chatApi.util.invalidateTags(['Chat']));
};
export const invalidateChatGroup = (dispatch) => {
    dispatch(chatApi.util.invalidateTags(['group']));
};

export const invalidateUnreadMessageCount = (dispatch) => {
    dispatch(chatApi.util.invalidateTags(['Unread-Message-Count']));
};

export const {
    useGetChatProfileQuery,
    useGetChatUsersQuery,
    useGetChatUserQuery,
    useGetChatGroupQuery,
    useGetOrSearchChatGroupsQuery,
    useGetChatSharedMediaQuery,
    useGetChatMessagesQuery,
    useGetGroupMessagesQuery,
    useGetGroupMembersQuery,
    useGetChatUnreadMessageCountQuery,
    useGetOrSearchChatUsersQuery,
    useGetOrSearchAllUsersQuery,
    useGetOrSearchGroupMembersQuery,
    useCreateBotMessageMutation,
    useCreateChatMessageMutation,
    useCreateGroupMessageMutation,
    useRemoveGroupMemberMutation,
    useCreateGroupMutation,
    useReadGroupMessageMutation,
    useReadChatMessageMutation,
    useDeliveredChatMessageMutation,
    useDeleteChatMessagesMutation,
    useDeleteGroupMessagesMutation,
    useDeleteChatMessageForMeMutation,
    useDeleteChatMessageForEveryoneMutation,
    useBlockChatUserMutation,
    useUnblockChatUserMutation,
    useMuteChatUserMutation,
    useUnmuteChatUserMutation,
    useAddGroupMembersMutation
} = chatApi;
