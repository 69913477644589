import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import UserAvatar from '../components/UserAvatar';
import GroupMessageBox from '../components/GroupMessageBox';
import GroupChatDetails from '../components/GroupChatDetails';
import { FaArrowDown } from 'react-icons/fa';
import {
    useGetChatProfileQuery,
    useGetChatGroupQuery,
    useGetGroupMessagesQuery
} from '@store/feature/chat/chatApi';
import SendGroupChat from '../components/SendGroupChat';
import { useParams } from 'react-router-dom';
import { useReadGroupMessageMutation } from '../../../../store/feature/chat/chatApi';
import { FiCommand } from 'react-icons/fi';


const GroupChatMessageContent = () => {
    const { id } = useParams();
    const { data: profile } = useGetChatProfileQuery();
    const { data: groupProfile, isLoading } = useGetChatGroupQuery(id);
    const { data: groupMessages } = useGetGroupMessagesQuery(id);
    const [readGroupMessage] = useReadGroupMessageMutation();

    const isSmallScreen = useMediaQuery('(max-width: 1080px)');

    const [showChatDetails, setShowChatDetails] = useState(false);
    const groupBottomRef = useRef(null);
    const [scrollToLatestMessage, setScrollToLatestMessage] = useState(true);

    const [currentDate, setCurrentDate] = useState('');
    const [dateStatus, setDateStatus] = useState(false);

    useEffect(() => {
        if (groupBottomRef.current) {
            groupBottomRef.current.scrollIntoView({ behavior: 'auto' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 100);
    }, [groupMessages]);

    const handleNotInView = useCallback(() => {
        setScrollToLatestMessage(false);
    }, []);

    const handleInView = useCallback(() => {
        setScrollToLatestMessage(true);
    }, []);

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    handleNotInView();
                } else {
                    handleInView();
                }
            });
        };

        const observerOptions = {
            root: null,
            threshold: 0
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        if (groupBottomRef.current) {
            observer.observe(groupBottomRef.current);
        }

        return () => {
            if (groupBottomRef.current) {
                observer.unobserve(groupBottomRef.current);
            }
        };
    }, [groupMessages, handleNotInView, handleInView]);

    const handleShowChatDetails = () => {
        setShowChatDetails(!showChatDetails);
    };

    const handleChatDetailsClose = () => {
        setShowChatDetails(false);
    };

    const groupMessagesById = (group) => {
        if (!group) return [];

        const groupedMessages = [];
        let currentGroup = [];

        group.forEach((message, index) => {
            if (
                currentGroup.length === 0 ||
                currentGroup[0].from === message.from
            ) {
                currentGroup.push(message);
            } else {
                groupedMessages.push(currentGroup);
                currentGroup = [message];
            }
        });

        if (currentGroup.length > 0) {
            groupedMessages.push(currentGroup);
        }

        return groupedMessages;
    };

    const groupedMessages = groupMessagesById(groupMessages);

    useEffect(() => {
        if (id && groupMessages?.length > 0) {
            const lastMessage = groupMessages[groupMessages.length - 1];
            readGroupMessage({ groupId: id, messageId: lastMessage?.uuid });
        }
    }, [id, groupProfile, groupMessages, readGroupMessage]);

    if (isLoading) {
        return (
            <Box className="flex items-center justify-center h-20 gap-4">
                <Typography
                    sx={{
                        color: 'teal',
                        fontSize: isSmallScreen ? '1rem' : '1.25rem',
                        fontWeight: 'bold'
                    }}
                >
                    Loading...
                </Typography>
                <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
            </Box>
        );
    }

    const handleScrollDown = () => {
        if (groupBottomRef.current) {
            groupBottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        setTimeout(() => setScrollToLatestMessage(true), 500);
    };

    // Function to format date to MM/DD/YYYY
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    };

    return (
        <Box sx={{ display: 'flex', gap: '3px' }}>
            <Box sx={{ flex: 1 }}>
                <Box
                    sx={{
                        backgroundColor: '#27485D',
                        height: '64px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: '16px',
                        position: 'sticky',
                        top: 0,
                        gap: '8px',
                        borderRadius: '4px 4px 0 0'
                    }}
                >
                    <div
                        className="hover:cursor-pointer flex items-center gap-3"
                        onClick={handleShowChatDetails}
                    >
                        <UserAvatar
                            imgSource={groupProfile?.image_url}
                            fullName={groupProfile?.name}
                            isActive={'active'}
                        />
                        <Typography
                            sx={{
                                width: '100%',
                                display: 'flex',
                                color: 'white',
                                cursor: 'pointer'
                            }}
                            variant="body2"
                            onClick={handleShowChatDetails}
                        >
                            {groupProfile?.name}
                        </Typography>
                    </div>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'calc(100dvh - 165px)'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            pt: '12px',
                            overflowY: 'scroll',
                            height: 'calc(100dvh - 265px)'
                        }}
                    >
                        <Box sx={{ mt: 'auto', width: '100%' }}>
                            {groupedMessages && groupedMessages.length > 0 ? (
                                groupedMessages.map((group, index) => {
                                    const firstMessageDate = formatDate(group[0]?.created_at);
                                    const previousGroupDate = index > 0 ? formatDate(groupedMessages[index - 1][0]?.created_at) : null;
                                    const showDateHeader = index === 0 || firstMessageDate !== previousGroupDate;

                                    return (
                                        <div key={index}>
                                            {showDateHeader && (
                                                <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', margin: '8px 0', color: 'gray' }}>
                                                    {firstMessageDate}
                                                </Typography>
                                            )}
                                            <GroupMessageBox
                                                groupMessages={group}
                                                profile={profile}
                                                setCurrentDate={setCurrentDate}
                                                setDateStatus={setDateStatus}
                                            />
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="flex justify-center items-center min-h-screen w-full pt-[300px]">
                                    <p>No messages available!</p>
                                </div>
                            )}
                            <div
                                id="groupBottom"
                                ref={groupBottomRef}
                                className="border-2 border-white"
                            />
                        </Box>
                    </Box>

                    {!scrollToLatestMessage && groupMessages && (
                        <div className="relative w-full">
                            <span className="absolute left-1/2 transform -translate-x-1/2 bottom-2">
                                <button
                                    className="z-100 rounded-full flex justify-center items-center bg-[#2FACFE] border-1 border-gray-300 w-8 h-8"
                                    onClick={handleScrollDown}
                                >
                                    <FaArrowDown color="white" />
                                </button>
                            </span>
                        </div>
                    )}

                    <Box>
                        <SendGroupChat />
                    </Box>
                </Box>
            </Box>

            {showChatDetails && (
                <Box
                    sx={{
                        height: 'calc(100dvh - 101px)',
                        borderRadius: '8px',
                        width: {
                            xs: '30%', // width for extra-small screens (mobile)
                            sm: '30%', // width for small screens (tablets) 600px and up
                            md: '40%', // width for medium screens (small laptops) 900px and up
                            lg: '50%' // width for large screens (desktops) 1200px and up
                        },
                        maxWidth: '356px',
                        overflow: 'auto',
                        backgroundColor: 'white',
                        margin: '0 auto' // Center the box horizontally
                    }}
                >
                    <GroupChatDetails
                        onClose={handleChatDetailsClose}
                        open={showChatDetails}
                    />
                </Box>
            )}
        </Box>
    );
};

export default GroupChatMessageContent;
