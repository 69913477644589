import { Box } from "@mui/material";
import React from "react";

const Typing = () => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                p: 1,
            }}
        >
            <p>Typing ...</p>
        </Box>
    );
};

export default Typing;
