import React from 'react';
import { List, Box, useMediaQuery, Typography } from '@mui/material';
import {
    useGetChatProfileQuery,
    useGetOrSearchChatUsersQuery
} from '@store/feature/chat/chatApi';
import { ChatCard } from './components/ChatCard';
import { FiCommand } from 'react-icons/fi';
import LogoutButton from './components/Logout/LogoutButton';

const ChatSidebarMessages = ({ searchText }) => {
    const { data: profile } = useGetChatProfileQuery();
    const { data: usersData, isLoading } =
        useGetOrSearchChatUsersQuery(searchText);

    const isSmallScreen = useMediaQuery('(max-width: 1080px)');

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    position: 'relative',
                    width: '100%',
                    height: '65%',
                    maxHeight: '100dvh',
                    overflowY: 'scroll',
                    zIndex: 1,
                    backgroundColor: 'white'
                }}
            >
                {isLoading && (
                      <Box className="flex items-center justify-center h-20 gap-4">
                      <Typography
                          sx={{
                              color: 'teal',
                              fontSize: isSmallScreen ? '1rem' : '1.25rem',
                              fontWeight: 'bold'
                          }}
                      >
                          Loading...
                      </Typography>
                      <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
                  </Box>
                )}
                <List
                    sx={{
                        display: 'flex',
                        pt: '12px',
                        flexDirection: 'column',
                        maxHeight:
                            'calc(100dvh - (101px + 64px + 16px + 40px + 8px))',
                        overflowY: 'scroll',
                        zIndex: 1
                    }}
                >
                    {usersData?.map((u, index) => {
                        return (
                            <ChatCard
                                imageURL={u?.user?.image_url}
                                name={u?.user?.fullname}
                                key={index}
                                userId={u?.user?.uuid}
                                messageCount={u?.unread_messages}
                                message={u?.latest_message?.body}
                                time={u?.latest_message?.created_at}
                                isActive={
                                    u?.user?.uuid === profile?.uuid ||
                                    u?.user?.status === 'active'
                                }
                            />
                        );
                    })}
                </List>
                <LogoutButton />
            </Box>
        </React.Fragment>
    );
};

export default ChatSidebarMessages;
