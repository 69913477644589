import React, { useState } from 'react';

import NextPage from './NextPage';
import { Box } from '@mui/material';

const GroupForm = ({ hideForm }) => {
    const [formData, setFormData] = useState({
        photo: null,
        name: '',
        comment: '',
        description: ''
    });

    const [error, setError] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const [dotted, setDotted] = useState('dotted');

    const handleChangeInput = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            const file = files[0];
            setImageSrc(URL.createObjectURL(file));
            setFormData((prev) => ({
                ...prev,
                [name]: file
            }));
            setDotted('');
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name) {
            setError('Name is required');
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('photo', formData.photo);
        formDataToSend.append('name', formData.name);
        formDataToSend.append('comment', formData.comment);
        formDataToSend.append('description', formData.description);
        hideForm();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <div className="max-w-md mx-auto p-1 bg-white rounded-lg">
                <form
                    onSubmit={handleSubmit}
                    className="space-y-6"
                    enctype="multipart/form-data"
                >
                    {/* <div className="flex flex-col items-center ">
                        <label
                            htmlFor="photo"
                            className={`relative ${isSmallScreen ? 'w-20' : 'w-32'} ${isSmallScreen ? 'h-20' : 'h-32'} flex items-center justify-center rounded-full border border-dotted border-gray-500 `}
                            style={{ border: `2px ${dotted} gray` }}
                        >
                            {imageSrc ? (
                                <img
                                    src={imageSrc}
                                    alt="Selected"
                                    className="absolute inset-0 w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                <span className="absolute inset-0 flex items-center justify-center">
                                    {isSmallScreen ? <span className='text-[9px]'>20 x 20</span>:<span className="text-[14px]">32 x 32</span>}
                                </span>
                            )}
                            <input
                                type="file"
                                id="photo"
                                name="photo"
                                accept="image/*"
                                onChange={handleChangeInput}
                                className={`opacity-0 w-full h-full hover:cursor-pointer ${isSmallScreen ? 'w-20' : 'w-32'} ${isSmallScreen ? 'h-20' : 'h-32'}`}
                            />
                        </label>
                        <div className="flex justify-between text-sm gap-3 border border-1 rounded-full px-4  pb-[1px] mt-3 shadow-md hover:cursor-pointer">
                            <label
                                htmlFor="photo"
                                className="mt-2 text-sm hover:cursor-pointer "
                            >
                                <MdFileUpload size={isSmallScreen?20:32} />
                            </label>
                            {!isSmallScreen &&<label
                                htmlFor="photo"
                                className="text-md text-gray-500 mt-[12px] font-semibold hover:cursor-pointer"
                            >
                                Upload Image
                            </label>}
                        </div>
                    </div> */}

                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            * Group Name
                        </label>

                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChangeInput}
                            className="mt-1 block w-full px-3 py-2 border border-blue-200 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        {error && (
                            <p className="text-red-500 text-sm mt-1">{error}</p>
                        )}
                    </div>
                </form>
            </div>
            <div className="py-2">
                <NextPage hideForm={hideForm} formData={formData} />
            </div>
        </Box>
    );
};

export default GroupForm;
