import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const LogoutModal = ({ open, handleClose, handleLogout }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="logout-modal-title"
            aria-describedby="logout-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '30%',
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="logout-modal-title" variant="h6" component="h2">
                    Confirm Logout
                </Typography>
                <Typography id="logout-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to logout?
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-evenly' }}>
                    <Button variant="contained" color="primary" onClick={handleLogout}>
                        Yes
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>
                        No
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default LogoutModal;