import React, { useState } from 'react';
import { Box, Modal, Switch, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BlockIcon from '@mui/icons-material/Block';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Divider from '@mui/material/Divider';
import BlockModal from './BlockModal';
import DeleteModal from './DeleteModal';
import MuteModal from './MuteModal';
import UnmuteModal from './UnmuteModal';
import UnBlockModal from './UnBlockModal';
import { useParams } from 'react-router-dom';
import {
    useGetChatSharedMediaQuery,
    useGetChatUserQuery
} from '@store/feature/chat/chatApi';
import UserAvatar from './UserAvatar';
import ImageModal from './ImageModal';
import { FiCommand } from 'react-icons/fi';

const ChatDetails = ({ onClose, open }) => {
    const { id } = useParams();
    const { data: userProfile, isLoading: isGetUserProfileLoading } =
        useGetChatUserQuery(id);
    const { data: sharedMedia } = useGetChatSharedMediaQuery(id);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const isSmallScreen = useMediaQuery('(max-width: 1080px)');

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setIsModalOpen(true);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sharedMedia.length);
        setSelectedImage(
            `/chat-media/${
                sharedMedia[(currentIndex + 1) % sharedMedia.length].file_url
            }`
        );
    };

    const handlePrevious = () => {
        setCurrentIndex(
            (prevIndex) =>
                (prevIndex - 1 + sharedMedia.length) % sharedMedia.length
        );
        setSelectedImage(
            `/chat-media/${
                sharedMedia[
                    (currentIndex - 1 + sharedMedia.length) % sharedMedia.length
                ].file_url
            }`
        );
    };

    // open mute modal when checked
    const handleChange = (event) => {
        if (event.target.checked) {
            handleOpenMuteModal();
        } else {
            handleOpenUnmuteModal();
        }
    };

    // open block modal
    const [openBlockModal, setOpenBlockModal] = useState(false);
    const handleOpenBlockModal = () => setOpenBlockModal(true);
    const handleCloseBlockModal = () => setOpenBlockModal(false);

    // unblock modal
    const [openUnblockModal, setOpenUnblockModal] = useState(false);
    const handleOpenUnblockModal = () => setOpenUnblockModal(true);
    const handleCloseUnblockModal = () => setOpenUnblockModal(false);

    // open delete modal
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const handleOpenDeleteModal = () => setOpenDeleteModal(true);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);

    // mute modal
    const [openMuteModal, setOpenMuteModal] = useState(false);
    const handleOpenMuteModal = () => setOpenMuteModal(true);
    const handleCloseMuteModal = () => setOpenMuteModal(false);

    // unmute modal
    const [openUnmuteModal, setOpenUnmuteModal] = useState(false);
    const handleOpenUnmuteModal = () => setOpenUnmuteModal(true);
    const handleCloseUnmuteModal = () => setOpenUnmuteModal(false);

    return (
        <>
            {/* block modal */}
            <Modal open={openBlockModal} onClose={handleCloseBlockModal}>
                <BlockModal handleClose={handleCloseBlockModal} />
            </Modal>
            {/* Unblock Modal */}
            <Modal open={openUnblockModal} onClose={handleCloseUnblockModal}>
                <UnBlockModal handleClose={handleCloseUnblockModal} />
            </Modal>
            {/* Delete Modal */}
            <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
                <DeleteModal handleClose={handleCloseDeleteModal} />
            </Modal>
            {/* Mute Modal */}
            <Modal open={openMuteModal} onClose={handleCloseMuteModal}>
                <MuteModal handleClose={handleCloseMuteModal} />
            </Modal>
            {/* UnMute Modal */}
            <Modal open={openUnmuteModal} onClose={handleCloseUnmuteModal}>
                <UnmuteModal handleClose={handleCloseUnmuteModal} />
            </Modal>
            <Box
                sx={{
                    backgroundColor: '#27485D',
                    height: '64px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    px: '16px',
                    position: 'sticky',
                    flex: '0 0 auto',
                    top: 0,
                    zIndex: 990
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '12px',
                        py: '16px'
                    }}
                >
                    <CloseIcon
                        sx={{
                            color: 'white',
                            cursor: 'pointer'
                        }}
                        onClick={onClose}
                    />
                    <Typography
                        sx={{
                            color: 'white'
                        }}
                    >
                        Contact Info
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    mt: '10px',
                    px: '18px',
                    overflowY: 'auto',
                    height: 'calc(100vh - 180px)'
                }}
            >
                <Box>
                    {isGetUserProfileLoading ? (
                        <Box className="flex items-center justify-center h-20 gap-4">
                            <Typography
                                sx={{
                                    color: 'teal',
                                    fontSize: isSmallScreen
                                        ? '1rem'
                                        : '1.25rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                Loading...
                            </Typography>
                            <FiCommand className="text-teal-500 text-2xl animate-spin-fast" />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mt: '16px',
                                gap: '8px'
                            }}
                        >
                            <UserAvatar
                                fullName={userProfile?.user?.fullname}
                                imgSource={userProfile?.user?.image_url}
                            />
                            <Typography
                                sx={{
                                    p: 0,
                                    m: 0,
                                    fontWeight: 'bold',
                                    fontSize: '20px'
                                }}
                            >
                                {userProfile?.user?.fullname}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                ></Box>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Name
                        </Typography>
                        <Typography
                            sx={{
                                color: 'black',
                                fontWeight: 'normal'
                            }}
                        >
                            {userProfile?.user?.fullname}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Email
                        </Typography>
                        <Typography
                            sx={{
                                color: 'black',
                                fontWeight: 'normal'
                            }}
                        >
                            {userProfile?.user?.username || 'N/A'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: 'bold'
                            }}
                        >
                            Company
                        </Typography>
                        <Typography
                            sx={{
                                color: 'black',
                                fontWeight: 'normal'
                            }}
                        >
                            {userProfile?.user?.company || 'PRICE BLICK'}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                >
                    <Box>
                        {sharedMedia && sharedMedia.length > 0 ? (
                            <Box>
                                <div className="flex justify-between gap-4">
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Shared Media
                                    </Typography>
                                    <div className="flex items-center ml-1">
                                        {sharedMedia.length <= 2 ? (
                                            ''
                                        ) : (
                                            <span className="items-center font-bold">
                                                +{sharedMedia.length - 2}{' '}
                                                more...
                                            </span>
                                        )}
                                    </div>{' '}
                                </div>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '8px',
                                        flexWrap: 'wrap',
                                        maxHeight: '200px',
                                        paddingTop: '8px'
                                    }}
                                >
                                    {sharedMedia
                                        .slice(0, 2)
                                        .map((media, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    position: 'relative',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                    borderRadius: '8px',
                                                    border: '1px solid #E0E0E0',
                                                    padding: '4px'
                                                }}
                                            >
                                                <img
                                                    src={`/chat-media/${media?.file_url}`}
                                                    alt="preview"
                                                    style={{
                                                        width: '100px',
                                                        height: '70px',
                                                        objectFit: 'cover',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() =>
                                                        handleImageClick(
                                                            `/chat-media/${media?.file_url}`
                                                        )
                                                    }
                                                />
                                            </Box>
                                        ))}
                                </Box>
                                {selectedImage && (
                                    <ImageModal
                                        isOpen={isModalOpen}
                                        onClose={() => setIsModalOpen(false)}
                                        imageUrl={selectedImage}
                                        onNext={handleNext}
                                        onPrevious={handlePrevious}
                                        sharedMedia={sharedMedia}
                                        handleImageClick={handleImageClick}
                                    />
                                )}
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                <Divider />{' '}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        mt: '16px'
                    }}
                >
                    <Box
                        sx={{
                            pb: '8px',
                            pt: '10px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '12px'
                            }}
                        >
                            <NotificationsIcon />
                            <Typography>Mute</Typography>
                        </Box>
                        <Switch
                            checked={userProfile?.muted}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                            size="small"
                        />
                    </Box>
                    {userProfile?.blocked ? (
                        <Box
                            sx={{
                                pb: '8px',
                                display: 'flex',
                                gap: '12px',
                                color: '#F15C6D',
                                cursor: 'pointer'
                            }}
                            onClick={handleOpenUnblockModal}
                        >
                            <BlockIcon />
                            <Typography>
                                Unblock {userProfile?.user?.fullname}
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                pb: '8px',
                                display: 'flex',
                                gap: '12px',
                                color: '#F15C6D',
                                cursor: 'pointer'
                            }}
                            onClick={handleOpenBlockModal}
                        >
                            <BlockIcon />
                            <Typography>
                                Block {userProfile?.user?.fullname}
                            </Typography>
                        </Box>
                    )}
                    <Box
                        sx={{
                            pb: '8px',
                            display: 'flex',
                            gap: '12px',
                            color: '#F15C6D',
                            cursor: 'pointer'
                        }}
                        onClick={handleOpenDeleteModal}
                    >
                        <DeleteOutlineOutlinedIcon />
                        <Typography>Delete chat</Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ChatDetails;
